import Vue from 'vue'
import {AclInstaller, AclCreate, AclRule} from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'public'

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
if (userInfo && userInfo.userRole) initialRole = userInfo.userRole
console.log(initialRole)

export default new AclCreate({
  initial: initialRole,
  notfound: '/pages/not-authorized',
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule('admin').generate(),
    isPublic: new AclRule('public').or('admin').or('seller').or('grocer').generate(),
    seller: new AclRule('seller').or('admin').generate(),
    grocer: new AclRule('grocer').or('admin').generate()
  }
})
