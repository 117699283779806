export default {
    fetchFeatures({commit}, data) {
        commit('SET_FEATURES', data.features)
    },
    addFeature({commit}, data) {
        commit('ADD_ITEM', data.feature)
    },
    updateFeature({commit}, data) {
        commit('UPDATE_FEATURE', data.feature)
    },
}