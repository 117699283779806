export default {
    ADD_ITEM(state, item) {
        state.orders.unshift(item)
    },
    SET_ORDERS(state, orders) {
        state.orders = orders
    },
    UPDATE_ORDER(state, order) {
        const orderIndex = state.orders.findIndex((o) => o.id === order.id)
        Object.assign(state.orders[orderIndex], order)
    },
    REMOVE_ITEM(state, order) {
        const ItemIndex = state.orders.findIndex((o) => o.id ===  order.id)
        state.orders.splice(ItemIndex, 1)
    }
}
