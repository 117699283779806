import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleCategory from './categories/moduleDataList.js'
import moduleFeature from './features/moduleDataList.js'
import moduleProduct from './products/moduleDataList.js'
import moduleVariants from './variants/moduleDataList.js'
import moduleCoupon from './coupons/moduleDataList.js'
import moduleDelivery from './delivery-areas/moduleDataList.js'
import moduleClients from './clients/moduleDataList.js'
import moduleOrders from './orders/moduleDataList.js'
import moduleUsers from './users/moduleDataList.js'
import modulePromotions from './promotions/moduleDataList.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleWarehouses from './warehouses/moduleDataList'
import moduleBrands from './brands/moduleDataList.js'
import moduleInputs from './inputs/moduleDataList'
import moduleOutputs from './outputs/moduleDataList'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    categories: moduleCategory,
    features: moduleFeature,
    products: moduleProduct,
    variants: moduleVariants,
    coupons: moduleCoupon,
    deliveries: moduleDelivery,
    clients: moduleClients,
    auth: moduleAuth,
    orders: moduleOrders,
    users: moduleUsers,
    promotions: modulePromotions,
    brands: moduleBrands,
    warehouses: moduleWarehouses,
    inputs: moduleInputs,
    outputs: moduleOutputs
  },
  strict: false
})
