export default {
  ADD_ITEM(state, item) {
    state.warehouses.unshift(item)
  },
  SET_WAREHOUSES(state, warehouses) {
    state.warehouses = warehouses
  },
  UPDATE_WAREHOUSE(state, warehouse) {
    const brandIndex = state.warehouses.findIndex((o) => o.id === warehouse.id)
    Object.assign(state.warehouses[brandIndex], warehouse)
  },
  REMOVE_ITEM(state, itemId) {
    const index = state.warehouses.findIndex((o) => o.id === itemId)
    state.warehouses.splice(index, 1)
  }
}
