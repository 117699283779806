export default {
    ADD_ITEM(state, item) {
        state.features.unshift(item)
    },
    SET_FEATURES(state, features) {
        state.features = features
    },
    UPDATE_FEATURE(state, feature) {
        const featureIndex = state.features.findIndex((f) => f.id === feature.id)
        Object.assign(state.features[featureIndex], feature)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.features.findIndex((p) => p.id === itemId)
        state.features.splice(ItemIndex, 1)
    }
}