// en, de, fr, pt
export default {
  es: {
    Dashboard: 'Dashboard',
    Catalogue: 'Catálogo',
    Categories: 'Categorías',
    Features: 'Características',
    Products: 'Productos',
    Sales: 'Ventas',
    Orders: 'Pedidos',
    Clients: 'Clientes',
    Coupons: 'Cupones',
    Delivery: 'Delivery',
    DeliveryAreas: 'Zonas de entrega',
    General: 'General',
    Users: 'Usuarios',
    Settings: 'Configuraciones',
    Sliders: 'Sliders',
    SlidersMobile: 'Sliders en móvil',
    Banners: 'Banners',
    MobileBanners: 'Banners en móvil',
    Variants: 'Presentaciones',
    Calendar: 'Calendario',
    Terms: 'T&C',
    Promotions: 'Promociones',
    Brands: 'Marcas',
    Warehouses: 'Almacenes',
    Warehouse: 'Almacén',
    Inputs: 'Ingresos',
    Outputs: 'Salidas',
    Politics: 'Políticas de garantía',
    Faqs: 'Faqs'
  }
}
