export default {
  fetchPromotions({commit}, data) {
    commit('SET_PROMOTIONS', data.promotions)
  },
  addPromotion({commit}, data) {
    commit('ADD_ITEM', data.promotion)
  },
  updatePromotion({commit}, data) {
    commit('UPDATE_PROMOTION', data.promotion)
  },
}
