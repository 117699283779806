export default {
  fetchInputs({commit}, data) {
    commit('SET_INPUTS', data.inputs)
  },
  addInput({commit}, data) {
    commit('ADD_ITEM', data.input)
  },
  updateInput({commit}, data) {
    commit('UPDATE_INPUT', data.input)
  },
}
