export default {
    ADD_ITEM(state, item) {
        state.deliveries.unshift(item)
    },
    SET_DELIVERIES(state, deliveries) {
        state.deliveries = deliveries
    },
    UPDATE_DELIVERY(state, delivery) {
        const couponIndex = state.deliveries.findIndex((d) => d.id === delivery.id)
        Object.assign(state.deliveries[couponIndex], delivery)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.deliveries.findIndex((d) => d.id === itemId)
        state.deliveries.splice(ItemIndex, 1)
    }
}