import Vue from 'vue'
import Router from 'vue-router'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    
    {
      // =============================================================================
      // Main layout routes
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard/',
          meta: {
            rule: 'isPublic',
            authRequired: true
          }
        },
        {
          path: '/dashboard/',
          name: 'dashboard',
          component: () => import('./views/Home.vue'),
          meta: {
            rule: 'isPublic',
            authRequired: true
          }
        },
        {
          path: 'catalogue/features/',
          name: 'features',
          component: () => import('./views/features/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'catalogue/categories/',
          name: 'categories',
          component: () => import('./views/categories/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'catalogue/variants/',
          name: 'variants',
          component: () => import('./views/variants/App'),
          meta: {
            rule: 'grocer',
            authRequired: true
          }
        },
        {
          path: 'catalogue/brands/',
          name: 'brands',
          component: () => import('./views/brands/App'),
          meta: {
            rule: 'grocer',
            authRequired: true
          }
        },
        {
          path: 'warehouse/warehouses/',
          name: 'warehouses',
          component: () => import('./views/warehouses/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/products/',
          name: 'products',
          component: () => import('./views/products/App'),
          meta: {
            rule: 'grocer',
            authRequired: true
          }
        },
        {
          path: 'warehouse/products/import',
          name: 'products-import',
          component: () => import('./views/products/Import'),
          meta: {
            rule: 'grocer',
            authRequired: true
          }
        },
        {
          path: 'warehouse/inputs/',
          name: 'inputs',
          component: () => import('./views/inputs/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/outputs/',
          name: 'outputs',
          component: () => import('./views/outputs/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'sales/orders/',
          name: 'coupons',
          component: () => import('./views/orders/App'),
          meta: {
            rule: 'seller',
            authRequired: true
          }
        },
        {
          path: 'sales/coupons/',
          name: 'coupons',
          component: () => import('./views/coupons/App'),
          meta: {
            rule: 'seller',
            authRequired: true
          }
        },
        {
          path: 'sales/promotions/',
          name: 'promotions',
          component: () => import('./views/promotions/App'),
          meta: {
            rule: 'seller',
            authRequired: true
          }
        },
        {
          path: 'sales/clients/',
          name: 'clients',
          component: () => import('./views/clients/App'),
          meta: {
            rule: 'seller',
            authRequired: true
          }
        },
        {
          path: 'delivery/delivery-areas/',
          name: 'deliveries',
          component: () => import('./views/delivery-areas/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'delivery/calendar/',
          name: 'calendar',
          component: () => import('./views/Calendar'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/general/',
          name: 'general',
          component: () => import('./views/Content'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/users/',
          name: 'users',
          component: () => import('./views/users/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/sliders/',
          name: 'sliders',
          component: () => import('./views/Slider'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/mobile-sliders/',
          name: 'mobileSliders',
          component: () => import('./views/MobileSliders'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/banners/',
          name: 'banners',
          component: () => import('./views/BannersDesktop'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/mobile-banners/',
          name: 'mobile-banners',
          component: () => import('./views/BannersMobile'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/terms/',
          name: 'banners',
          component: () => import('./views/Terms'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/import/',
          name: 'banners',
          component: () => import('./views/ImportProducts'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/faqs/',
          name: 'faqs',
          component: () => import('./views/Faqs'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/politics/',
          name: 'banners',
          component: () => import('./views/Politics'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('./views/pages/Error404.vue'),
          meta: {
            rule: 'isPublic'
          }
        }
      ]
    },
    // Full Page Layout
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/login/Login.vue'),
          meta: {
            rule: 'isPublic',
          }
        },
        // Redirect to 404 page, if no match found
        {
          path: '*',
          redirect: '/pages/error-404',
          meta: {
            rule: 'isPublic'
          }
        }
      ]
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {
    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser
    
    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!(firebaseCurrentUser)) {
        router.push({path: '/pages/login', query: {to: to.path}})
      }
    }
    return next()
  })
  
})

export default router
