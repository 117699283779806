export default {
  fetchWarehouses({commit}, data) {
    commit('SET_WAREHOUSES', data.warehouses)
  },
  addWarehouse({commit}, data) {
    commit('ADD_ITEM', data.warehouse)
  },
  updateWarehouse({commit}, data) {
    commit('UPDATE_WAREHOUSE', data.warehouse)
  },
}
