export default {
  ADD_ITEM(state, item) {
    state.promotions.unshift(item)
  },
  SET_PROMOTIONS(state, promotions) {
    state.promotions = promotions
  },
  UPDATE_PROMOTION(state, promotion) {
    const index = state.promotions.findIndex((c) => c.id === promotion.id)
    Object.assign(state.promotions[index], promotion)
  },
  REMOVE_ITEM(state, itemId) {
    const item = state.promotions.findIndex((c) => c.id === itemId)
    state.promotions.splice(item, 1)
  }
}
