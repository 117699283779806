export default {
  fetchOutputs({commit}, data) {
    commit('SET_OUTPUTS', data.outputs)
  },
  addOutput({commit}, data) {
    commit('ADD_ITEM', data.output)
  },
  updateOutput({commit}, data) {
    commit('UPDATE_OUTPUT', data.output)
  },
}
