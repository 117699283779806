export default {
  fetchProducts({commit}, data) {
    commit('SET_PRODUCTS', data.variants)
  },
  addProduct({commit}, data) {
    commit('ADD_ITEM', data.variant)
  },
  updateProduct({commit}, data) {
    commit('UPDATE_PRODUCT', data.variant)
  },
}
