export default {
    ADD_ITEM(state, item) {
        state.clients.unshift(item)
    },
    SET_CLIENTS(state, clients) {
        state.clients = clients
    },
    UPDATE_CLIENT(state, client) {
        const couponIndex = state.clients.findIndex((c) => c.id === client.id)
        Object.assign(state.clients[couponIndex], client)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.clients.findIndex((c) => c.id === itemId)
        state.clients.splice(ItemIndex, 1)
    }
}