export default {
  ADD_ITEM(state, item) {
    state.brands.unshift(item)
  },
  SET_BRANDS(state, brands) {
    state.brands = brands
  },
  UPDATE_BRAND(state, brand) {
    const brandIndex = state.brands.findIndex((o) => o.id === brand.id)
    Object.assign(state.brands[brandIndex], brand)
  },
  REMOVE_ITEM(state, itemId) {
    const index = state.brands.findIndex((o) => o.id === itemId)
    state.brands.splice(index, 1)
  }
}
