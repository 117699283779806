export default {
    fetchOrders({commit}, data) {
        commit('SET_ORDERS', data.orders)
    },
    addOrder({commit}, data) {
        commit('ADD_ITEM', data.order)
    },
    updateOrder({commit}, data) {
        commit('UPDATE_ORDER', data.order)
    },
    deleteOrder({commit}, data) {
        commit('REMOVE_ITEM', data.order)
    }
}
