export default {
  ADD_ITEM(state, item) {
    state.outputs.unshift(item)
  },
  SET_OUTPUTS(state, outputs) {
    state.outputs = outputs
  },
  UPDATE_OUTPUT(state, output) {
    const outputIndex = state.outputs.findIndex((o) => o.id === output.id)
    Object.assign(state.outputs[outputIndex], output)
  }
}
