export default {
    ADD_ITEM(state, item) {
        state.variants.unshift(item)
    },
    SET_PRODUCTS(state, products) {
        state.variants = products
    },
    UPDATE_PRODUCT(state, product) {
        const productIndex = state.variants.findIndex((f) => f.id === product.id)
        Object.assign(state.variants[productIndex], product)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.variants.findIndex((p) => p.id === itemId)
        state.variants.splice(ItemIndex, 1)
    }
}
