export default {
    fetchClients({commit}, data) {
        commit('SET_CLIENTS', data.clients)
    },
    addClient({commit}, data) {
        commit('ADD_ITEM', data.client)
    },
    updateClient({commit}, data) {
        commit('UPDATE_CLIENT', data.client)
    },
}