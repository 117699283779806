export default {
  ADD_ITEM(state, item) {
    state.inputs.unshift(item)
  },
  SET_INPUTS(state, inputs) {
    state.inputs = inputs
  },
  UPDATE_INPUT(state, input) {
    const inputIndex = state.inputs.findIndex((f) => f.id === input.id)
    Object.assign(state.inputs[inputIndex], input)
  }
}
