export default {
    fetchCoupons({commit}, data) {
        commit('SET_COUPONS', data.coupons)
    },
    addCoupon({commit}, data) {
        commit('ADD_ITEM', data.coupon)
    },
    updateCoupon({commit}, data) {
        commit('UPDATE_COUPON', data.coupon)
    },
}