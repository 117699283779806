import Vue from 'vue'

const round = (number) => {
  if (number) {
    return round2Decimals(number)
  } else {
    return 0
  }
}

function round2Decimals(number) {
  if (number) {
    return number.toFixed(2)
  } else {
    return 0
  }
}

Vue.filter('round', round)
