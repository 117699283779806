export default {
  fetchBrands({commit}, data) {
    commit('SET_BRANDS', data.brands)
  },
  addBrand({commit}, data) {
    commit('ADD_ITEM', data.brand)
  },
  updateBrand({commit}, data) {
    commit('UPDATE_BRAND', data.brand)
  },
}
