import firebase from 'firebase/app'

// Initialize Firebase
let config = {
  apiKey: "AIzaSyARA647FIgHFF_eunYJL1SM2FqeMXJk9nk",
  authDomain: "royal-tech-ee4b4.firebaseapp.com",
  databaseURL: "https://royal-tech-ee4b4.firebaseio.com",
  projectId: "royal-tech-ee4b4",
  storageBucket: "royal-tech-ee4b4.appspot.com",
  messagingSenderId: "1024745288856",
  appId: "1:1024745288856:web:03d0aa7fde4830f0b40ced",
  measurementId: "G-VM5KCYHDJZ"
};
firebase.initializeApp(config)
