export default {
    fetchDeliveries({commit}, data) {
        commit('SET_DELIVERIES', data.deliveries)
    },
    addDelivery({commit}, data) {
        commit('ADD_ITEM', data.delivery)
    },
    updateDelivery({commit}, data) {
        commit('UPDATE_DELIVERY', data.delivery)
    },
}