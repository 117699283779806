import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// Calendar
import VCalendar from 'v-calendar'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

// axios
import axios from 'axios'

Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'

// Firebase
import '@/firebase/firebaseConfig'

// ACL
import acl from './acl/acl'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// Vuexy Admin Filters
import './filters/filters'
import './filters/rounding'

// Clipboard
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

// VeeValidate
import VeeValidate from 'vee-validate'

const attributesAr = {
  name: 'nombre',
  description: 'descripción',
  normalPrice: 'precio normal',
  priceDiscount: 'precio con descuento',
  relatedProducts: 'productos relacionados',
  order: 'orden',
  unitySAP: 'unidad de medida sap',
  descriptionSAP: 'descripción sap',
  label: 'etiqueta',
  feature: 'característica',
  featureValue: 'valor de característica',
  code: 'código',
  percentage: 'porcentaje',
  startDate: 'fecha de inicio',
  endDate: 'fecha de fin',
  sapName: 'nombre de SAP',
  days: 'días disponibles',
  price: 'precio',
  schedule: 'horario',
  minimumOrder: 'pedido mínimo',
  phone: 'celular',
  title: 'titulo',
  role: 'rol',
  principalProduct: 'producto principal',
  typesOfPromotions: 'tipo de promoción',
  product: 'producto',
  category: 'categoría',
  amount: 'monto',
  gift: 'obsequio',
  quantity: 'cantidad',
  lat: 'latitud',
  long: 'longitud',
  businessName: 'razón social',
  department: 'departamento',
  province: 'provincia',
  district: 'distrito',
  warehouse: 'almacén',
  observation: 'observación',
  motive: 'motivo',
  address: 'dirección',
  brand: 'marca',
  points: 'puntos',
  expirationPoint: 'tiempo de expiración',
  position: 'orden',
  weight: 'peso'
}

Vue.use(VeeValidate, {
  dictionary: {
    es: {attributes: attributesAr}
  }
})

// Date picker
import Datepicker from 'vuejs-datepicker';
import {es} from 'vuejs-datepicker/dist/locale';

Datepicker.props.language.default = () => es;
Vue.component('datepicker', Datepicker);

// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'

Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false

// Translate validations
import {Validator} from 'vee-validate';
import es_validator from 'vee-validate/dist/locale/es';

Validator.localize('es', es_validator);

// Moment
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
